body.offline {
  min-height: auto;
  background: url('../img/bkgnd-offline-joy.svg') 32% / cover no-repeat;
}
body.online {
  min-height: auto;
  background: url('../img/bkgnd-stone-forest.svg') 32% / cover no-repeat;
}
.lang-switcher {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  margin: 0;
  padding: 0;
  z-index: 1035;
  border-bottom: #555 solid 3px;
  overflow: hidden;
}
.lang-switcher a {
  margin-top: 5px;
  color: #eeeeee;
  text-decoration: none;
}
.lang-switcher a.active,
.lang-switcher a:hover,
.lang-switcher a:focus {
  color: #ffffff;
  font-weight: bold;
}
.not-set {
    color: #c55;
    font-style: italic;
}
a.asc:after, a.desc:after {
    position: relative;
    top: 1px;
    display: inline-block;
    font-family: 'Glyphicons Halflings';
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    padding-left: 5px;
}
a.asc:after {
    content: "\e151";
}
a.desc:after {
    content: "\e152";
}
.sort-numerical a.asc:after {
    content: "\e153";
}
.sort-numerical a.desc:after {
    content: "\e154";
}
.sort-ordinal a.asc:after {
    content: "\e155";
}
.sort-ordinal a.desc:after {
    content: "\e156";
}
.grid-view td {
    white-space: nowrap;
}
.grid-view .filters input,
.grid-view .filters select {
    min-width: 50px;
}
.hint-block {
    display: block;
    margin-top: 5px;
    color: #999;
}
.error-summary {
    color: #a94442;
    background: #fdf7f7;
    border-left: 3px solid #eed3d7;
    padding: 10px 20px;
    margin: 0 0 15px 0;
}
.nav li > form > button.logout {
  padding: 15px;
  border: none;
}
@media(max-width:767px) {
  .nav li > form > button.logout {
    display:block;
    text-align: left;
    width: 100%;
    padding: 10px 15px;
  }
}
.nav > li > form > button.logout:focus,
.nav > li > form > button.logout:hover {
  text-decoration: none;
}
.nav > li > form > button.logout:focus {
  outline: none;
}
.lang-selector {
  position: absolute;
  top: 0;
}
.row.lang-select {
  text-align: center;
}
.row.lang-select .col-3,
.row.lang-select .col-4,
.row.lang-select .col-6 {
  height: 34px;
}
.row.lang-select .lang_0 {
  background-color: #81b6e6;
}
.row.lang-select .lang_1 {
  background-color: #9de89a;
}
.row.lang-select .lang_2 {
  background-color: #ecb070;
}
.row.lang-select .lang_3 {
  background-color: #7ed7e6;
}
.row.lang-select .item {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  color: #333333;
  text-transform: uppercase;
}
.row.lang-select a:hover,
.row.lang-select a:active,
.row.lang-select a:focus {
  text-decoration: none;
}
.row.lang-select .item.active {
  font-weight: bold;
}
.navbar.fixed-top {
  top: 34px;
}
.navbar.py-3 {
  padding-top: 1.3em !important;
}
.navbar-brand {
  margin-right: 4rem;
}
.navbar-brand.logo,
.navbar-brand img {
  width: 18rem;
  height: auto;
  padding-top: 5px;
  border-width: 2px;
  border-color: rgb(63,208,50);
}
@media(max-width: 991.98px) {
  .navbar-brand {
    margin-right: 2rem;
  }
  .navbar-brand.logo,
  .navbar-brand img {
    width: 8rem;
  }
}
.collapse.navbar-collapse {
  padding-top: .8rem;
}
.dropdown-menu {
  min-width: 15rem;
  background-color: #f4e0ca;
}
.nav-item {
  margin-right: .6em;
}
.nav-item .dropdown-menu.show .dropdown-item {
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  font-size: 0.8rem;
  text-transform: uppercase;
  padding-right: 15px;
  padding-left: 15px;
  color: rgba(0,0,0,.5);
  border-bottom: 1px dotted #ffffff;
}
.nav-item .dropdown-menu.show .dropdown-item:hover,
.nav-item .dropdown-menu.show .dropdown-item:focus {
  background-color: #ffffff;
  color: rgba(0,0,0,1);
}
.nav-item .dropdown-menu.show .dropdown-item.active,
.nav-item .dropdown-menu.show .dropdown-item:active {
  background-color: #b6e2ec;
  color: rgba(0,0,0,.8);
}
.nav-item .nav-link {
  padding-right: .5rem;
  padding-left: 0;
  font-weight: 400;
  text-transform: uppercase;
  color: #fff !important;
}
.nav-item .nav-link:hover,
.nav-item .nav-link:focus {
  color: #ddd !important;
}
@media(max-width: 991.98px) {
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    padding-bottom: 20px;
  }
}
@media(min-width: 992px) {
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    /* background-color: #fffcefc9; */
    background-color: #fffdf3d4;
  }
}
.dropdown-item {
  display: block;
  width: 100%;
  padding: .25rem 0;
  clear: both;
  font-weight: 600;
  color: #212529;
  text-align: left;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-toggle::after {
  display: inline-block;
/*  margin-right: 1em; */
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid;
  border-right: .3em solid transparent;
  border-bottom: 0;
  border-left: .3em solid transparent;
}
@media(max-width: 1198.98px){
  .dropdown-toggle::after {
    margin-right: .2em;
  }
}
.navbar.bg-dark {
  margin-top: 36px;  /* USE ONLY IF MULTILINGUAL SITE IS ACTIVE */
  background-color: #51ac9278 !important;
}
/* USE FOLLOWING DECLARATION GROUP ONLY IF MULTILINGUAL SITE NOT ACTIVE */
/*
.lang-switcher {
  height: 0;
  opacity: 0;
  display: none;
}
*/
.text-logo-bar {
  overflow: hidden;
  border-top: 1px solid #ffffff4f;
  background-color: #00ffb778 !important;
  height: 40px;
  padding: 6px 0;
}
.text-logo-bar .col-12 {
  color: #fff;
}
@media(max-width: 991.98px) {
  .text-logo-bar {
    display: none;
    opacity: 0;
    height: 0;
  }
}
.text-logo-bar span {
  letter-spacing: .05rem;
}
footer {
  background-color: #21ca8bc7;
}
.row.return-button {
  padding: 40px 0 30px 0;
}
hr.spacer {
  margin-top: 2em;
  margin-bottom: 2em;
}
hr.content-divider-1 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
.popup-gallery .vignettes .thumbnail {
  padding: 12px;
}
.popup-gallery .vignettes .img-fluid {
  display: block;
  border: 4px #ffffff solid;
  border-radius: 20px;
}
@media(max-width: 991.98px) {
  .contact form {
    margin-top: 15px;
  }
}
.contact form h3 {
  font-size: 2.5rem;
  color: #fff;
  line-height: 1;
}
.contact form p.intro {
  color: #ffffffde;
}
#contact-form label {
  color: #ececec;
}
.form-group.day-email {
  display: none;
  opacity: 0;
  padding-left: -3000px;
}
.form-group #Imageid,
.form-control.captcha {
  float: left;
  width: 50%;
  margin-bottom: 20px;
  padding-right: 20px;
}
.row.form-group.six {
  padding-bottom: 15px;
}
.alert-success {
  color: #155724;
  background-color: #baf2c8;
  border-color: #4da461;
}
.mfp-bg {
  background: url('../img/overlay.png');
}
.mfp-fade.mfp-bg {
  opacity: 0;
  -webkit-transition: all 0.15s ease-out; 
  -moz-transition: all 0.15s ease-out; 
  transition: all 0.15s ease-out;
}
.mfp-fade.mfp-bg.mfp-ready {
  opacity: 0.8;
}
.mfp-fade.mfp-bg.mfp-removing {
  opacity: 0;
}
.mfp-fade.mfp-wrap .mfp-content {
  opacity: 0;
  -webkit-transition: all 0.15s ease-out; 
  -moz-transition: all 0.15s ease-out; 
  transition: all 0.15s ease-out;
}
.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1;
}
.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
  opacity: 0;
}
.row.page-images.popup-gallery {
  margin-top: 50px;
}
.page-images.popup-gallery .img-fluid {
  border: #fff 1px solid;
  margin-bottom: .4em;
}
.body-text div a {
  color: #00abff;
}
.body-text div a:hover,
.body-text div a:link,
.body-text div a:focus {
  color: #ff0000;
  text-decoration: underline;
}
@media(max-width: 991.98px) {
  .mobile-labels {
    display: block;
    opacity: 1;
    height: auto;
  }
}
@media(min-width: 992px) {
  .mobile-labels {
    display: none;
    opacity: 0;
    height: 0;
  }
}
.home .search-banner {
  background-color: #4985a2;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 2px #333333 solid;
}
.home .search-banner .icofont-search-2 {
  background-color: #f00;
  border: 2px solid #fff;
  border-radius: 10px;
  margin-left: 30px;
  color: #ffffff;
  font-size: 2.5rem;
  padding: 10px;
}
.home .search-banner a:hover {
  text-decoration: none;
}
.home .search-banner h3 {
  color: #fff;
  letter-spacing: .9px;
  vertical-align: middle;
  font-size: 2.5rem;
}
.home .cta-1 .pb-2.pb-lg-1 {
  padding-right: 20px;
}
.home .notice-1 .container .text-white.border.rounded {
  background-color: #fdaf4bad;
}
.home .landing-page .form-group {
  margin-bottom: 10px;
}
.fit-cover {
  object-fit: cover;
}
.hero-section .border.rounded {
/*  background-color: #ffac8482; */
  background-color: #fb520029;
  border: 1px #fff solid;
  border-radius: 10px;
}
.home .hero-section h1 {
  font-size: 3rem;
  color: #e6c9a4;
}
@media(max-width: 767.98px) {
  .home .hero-section h1 {
    font-size: 2rem;
    color: #e6c9a4;
  }
}
.cta-1 .bg-primary.border.rounded {
  background-color: #628da29e !important;
  border: 1px solid #fff;
}
.cta-1 .btn.btn-light {
  min-width: 200px;
  text-transform: uppercase;
}
.fas.fa-file-pdf {
  font-size: 1.3rem;
  margin-right: 10px;
  color: #f00;
}
.cta-1 .first {
  font-size: 1rem;
}
.cta-1 .second {
  font-size: .9rem;
}
.news-vignettes-1 .bg-primary.border.rounded {
  background-color: #45359745 !important;
}
#map {
  border-top: 4px #dddddd solid;
  border-bottom: 4px #dddddd solid;
  height: 640px;
  width: 100%;
  position: relative;
}
.mapboxgl-popup {
  padding: 20px;
  max-width: 340px;
}
.mapboxgl-popup-content {
  text-align: center;
  font-family: 'Open Sans', sans-serif;
}
#marker {
  padding: 4px;
  background-image: url('../img/rodgeur-2.png');
  background-size: cover;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  cursor: pointer;
}
.mapboxgl-popup {
  max-width: 200px;
}
.inner-page-content {
  padding-top: 2.2em;
  padding-bottom: 2em;
}
.inner-page-content .main-inner-content .body-text {
  margin-top: 16px;
  margin-bottom: 24px;
}
.main-inner-content h1 {
  font-size: 4.25rem;
  color: #fffc;
}
.main-inner-content h2 {
  font-size: 2rem;
  color: #0007;
}
.main-inner-content p {
  color: #183437;
}
section.gallery-1 {
  padding-top: 0;
  padding-bottom: 2.2em;
}
.image-descrip {
  font-size: .8rem;
}
.testimonials {
  background-color: #81fff38c;
  margin-top: 1.5em;
  padding-top: 3em;
  padding-bottom: 3em;
}
.testimonials h2 {
  font-size: 3rem;
  color: #333;
  text-transform: uppercase;
}
.testimonials .intro h2,
.testimonials .intro p {
  text-align: center;
}
.testimonials .intro p {
  margin-bottom: 30px;
}
.testimonials .description {
  display: block;
  background-color: #dbffc38f;
  padding: 10px;
  min-height: 152px;
  text-align: center;
  font-size: .8em;
}
.testimonials .author {
  padding-top: 15px;
  min-height: 90px;
}
.testimonials .row.author .col-3 {
  padding-right: 5px;
}
.testimonials .row.author .col-9 {
  padding-left: 5px;
}
.testimonials img {
  padding: 5px;
}
.testimonials .name {
  padding-top: .7rem;
  font-size: .7em;
  text-align: left;
}
.testimonials .summary {
  font-size: .65em;
  text-align: left;
}
.testimonials .rounded-circle {
  display: block;
  border: 1px solid #ccc;
  border-radius: 50%;
}
.testimonials .owl-item .container .row.author .name {
  border-bottom: 2px solid #dbffc38f;
  padding-bottom: 2.5rem;
}
footer .link-light-color,
footer a {
  margin-right: .5rem;
  margin-left: .1rem;
  text-decoration: none;
  color: #595959;
}
footer .link-light-color:hover,
footer .link-light-color:focus,
footer .link-light-color:active,
footer a:hover,
footer a:focus,
footer a:active {
  text-decoration: none;
  color: #fff;
}
footer a.white {
  margin-right: .5rem;
  margin-left: .5rem;
  text-decoration: none;
  color: #fff;
}
footer a.white:hover,
footer a.white:focus,
footer a.white:active {
  text-decoration: underline;
  color: #fff;
}
.sponsors .feature-box {
  margin-bottom: 3rem;
  padding: .8rem 2rem;
  background-color: #ffffffd1;
  border-radius: 0.3rem;
}
body.var-1 .sponsors .feature-box {
  margin-bottom: 3rem;
  padding: .8rem 2rem;
  background-color: #fff88e9e;
  border-radius: 0.3rem;
}
body.var-2 .sponsors .feature-box {
  margin-bottom: 3rem;
  padding: .8rem 2rem;
  background-color: #ffffffd1;
  border-radius: 0.3rem;
}
body.var-3 .sponsors .feature-box {
  margin-bottom: 3rem;
  padding: .8rem 2rem;
  background-color: #8ee5ffa8;
  border-radius: 0.3rem;
}
body.var-4 .sponsors .feature-box {
  margin-bottom: 3rem;
  padding: .8rem 2rem;
  background-color: #ff9575bd;
  border-radius: 0.3rem;
}
.highlighted-content {
  margin-bottom: 2rem;
  padding: 2rem 0;
  background-color: #e3f5d296;
}
.var-1 .highlighted-content {
  margin-bottom: 2rem;
  padding: 2rem;
  background-color: #c5f7e47a;
}
.var-2 .highlighted-content {
  margin-bottom: 2rem;
  padding: 2rem;
  background-color: #e3f5d296;
}
.var-3 .highlighted-content {
  margin-bottom: 2rem;
  padding: 2rem;
  background-color: #fdffc185;
}
.var-4 .highlighted-content {
  margin-bottom: 2rem;
  padding: 2rem;
  background-color: #cccff5a3;
}
.home .card {
  background-color: #fff5cccc;
}
.page-27 .card {
  background-color: #ffffffc7;
  margin-bottom: 1.5rem;
}
@media(max-width:767px) {
  .page-27 .card img {
    display:block;
    text-align: left;
    width: 100%;
    padding: 10px 15px;
  }
}
.page-19 .card-columns,
.page-20 .card-columns {
  column-count: 3;
}
.page-19 .card-columns .card,
.page-20 .card-columns .card {
  display: inline-block;
  column-break-inside: avoid;
  border: 2px #fff solid;
  border-radius: 2px;
  background-color: #fff;
}
.page-19 .card-body,
.page-20 .card-body {
  padding: 0;
}
.page-19 .card-body .img-fluid,
.page-20 .card-body .img-fluid {
  padding: 0;
  width: 100%;
}
.card-body h6 {
  position: absolute;
  top: 0;
  right: 0;
  color: #ffffff96;
  font-size: 1.1rem;
  background-color: #a8a8a869;
  padding: 4px 8px;
}
.carousel-control-prev-icon, .carousel-control-next-icon {
  width: 40px !important;
  height: 40px !important;
}
.lead p {
  font-size: 1.1rem;
}
.inside-page-wrap,
.site-contact,
.club-member-signup {
  padding-bottom: 1.5rem;
}
.images-gallery-item {
  margin-bottom: 2rem;
}
.img-as-bkgnd {
  opacity: 1;
  -webkit-transition: all .7s ease;
  -o-transition: all .7s ease;
  transition: all .7s ease;
}
.img-as-bkgnd:hover {
  opacity: .6;
}
.main-content hr {
  border-color: #555;
}
.main-content .container hr {
  border-color: #fff;
  opacity: 1;
}
.main-content h3 {
  color: #eadab6e0;
  margin-bottom: .5rem;
}
.main-content .summary-span,
.main-content .summary-span p,
.main-content .summary-span h4,
.main-content .summary-span h5,
.main-content .summary-span h6 {
  margin-bottom: .8rem;
  color: #fff;
  font-size: 1.1rem;
  font-style: italic;
  line-height: 1.2;
}
.main-content .summary-span h4,
.main-content .body-span h4 {
  color: #fffaccdb;
}
.main-content .summary-span h5,
.main-content .body-span h5 {
  color: #ccfdffdb;
}
.main-content .summary-span h6,
.main-content .body-span h6 {
  color: #d0d7fd;
}
.main-content .body-span p,
.main-content .body-span ul,
.main-content .body-span li {
  margin-bottom: .5rem;
  color: #fff;
  font-size: 1.1rem;
  line-height: 1.3;
}
.main-content .body-span h4,
.main-content .body-span h5,
.main-content .body-span h6 {
  margin-bottom: .8rem;
  color: #fff;
  font-size: 1.1rem;
  line-height: 1.4;
}
.main-content .body-span ul {
  margin-left: .25rem;
}
.main-content .body-span li {
  margin-left: .2rem;
}
.main-content .summary-span a,
.main-content .body-span a {
  color: #acfff7;
}
.main-content .summary-span a:hover,
.main-content .summary-span a:focus,
.main-content .summary-span a:active,
.main-content .body-span a:hover,
.main-content .body-span a:focus,
.main-content .body-span a:active {
  color: #ffeeae;
}
.main-content .summary-span,
.main-content .body-span,
.main-content p,
.main-content .summary-span p,
.main-content .body-span p,
.main-content .summary-span span,
.main-content .body-span span,
.main-content h3,
.main-content .summary-span h3,
.main-content .body-span h3,
.main-content h4,
.main-content .summary-span h4,
.main-content .body-span h4,
.main-content h5,
.main-content .summary-span h5,
.main-content .body-span h5,
.main-content h6,
.main-content .summary-span h6,
.main-content .body-span h6 {
  color: #fff;
}
.main-content .body-span h3,
.main-content .documents h3 {
  text-transform: uppercase;
  font-size: 2.4rem;
}
.main-content .body-span h4,
.main-content .documents h4 {
  display: block;
  margin-bottom: 1rem;
  text-transform: uppercase;
  font-size: 1.6rem;
  font-weight: normal;
}
.main-content .body-span h4 strong,
.main-content .documents h4 strong {
  margin-bottom: 1rem;
  text-transform: uppercase;
  font-size: 1.5rem;
  font-weight: normal;
  color: #ffdab3;
}
.main-content .site-contact h4 {
  margin-bottom: 1rem;
  text-transform: uppercase;
  font-size: 1.5rem;
  font-weight: normal;
  color: #ce5863;
}
.main-content .contact-page.site-contact h4 {
  color: #c43d5d;
}
.headline-span,
.headline-span p {
  font-size: 1.3rem;
  color: #333;
}
.pdf-docs .pdf-icon .fas {
  font-size: 2rem;
  color: #fff;
  margin-right: 10px;
}
.pdf-docs .pdf-icon .doc-link {
  text-transform: uppercase;
  text-decoration: none;
  color: #fff;
}
.pdf-docs .pdf-icon .doc-link:hover {
  text-decoration: none;
  color: #000;
}
.pdf-docs .col-12 {
  margin-bottom: 1rem;
}
.pdf-docs .doc-tile {
  padding: 1rem 1.2rem;
  background-color: #ffaf5a8a;
  border-radius: 10px;
}
.container.events {
  margin-bottom: 2rem;
}
.calendar .highlighted-content {
  margin-bottom: 0;
}
.calendar-tile {
  margin-bottom: .3rem;
  padding: 1rem 1.2rem 0 1rem;
  background-color: #c6b6b6b8;
  border: 1px solid #fff;
  border-radius: 10px;
}
.calendar-tile h4 {
  margin-bottom: 0;
}
.calendar-tile .event-span-1a {
  text-align: center;
  color: #fff;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: .8;
  padding: .6rem 1.4rem;
  border-radius: 7px;
}
.calendar-tile .event-span-1a.past {
  background-color: #ffe9b68c;
}
.calendar-tile .event-span-1a.red {
  background-color: #ff000075;
}
.calendar-tile .event-span-1a.green {
  background-color: #00e89075;
}
.calendar-tile .event-span-1b {
  text-align: center;
  margin-top: .3rem;
}
.calendar-tile .event-span-1c {
  margin-bottom: 14px;
  text-align: center;
  line-height: .9;
}
.calendar-tile .event-span-2 {
  display: block;
  margin-bottom: 1rem;
}
.calendar-tile .event-span-2 p {
  margin-bottom: 0;
}
.calendar-tile .event-span-3 {
  margin-bottom: 14px;
  text-align: right;
}
.calendar-tile .event-span-3 .fas.fa-file-pdf {
  font-size: 3.3rem;
}
.calendar-tile .event-span-3 a.doc-link {
  padding: 20px 8px 20px 20px;
}
@media(max-width: 767.98px) {
  .calendar-tile .event-span-3 .fas.fa-file-pdf {
    font-size: 2rem;
  }
  .calendar-tile .event-span-3 a.doc-link {
    padding-top: 4px;
    padding-bottom: 4px;
    width: 100%;
  }
}
.page-13 h5 {
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  padding-bottom: .3rem;
  border-bottom: 1px #333 solid;
}
.body-span ul {
  padding-left: .9rem;
}
.body-span a {
  color: #000;
  text-decoration: underline;
}
.body-span a:hover,
.body-span a:focus {
  color: #fff;
  text-decoration: underline;
}
.junior-course-calendar {
  width: 100%;
  margin-bottom: 1.5rem;
}
.widgets-alerts {
  background-color: #fff;
}
.widgets-alerts .alert {
  margin-bottom: 0;
}
.img-fluid.ambience {
  background: url('../img/ambience-photo-contact.jpg');
  background-size: cover;
  background-position: center;
  border: 8px #ffffff solid;
  min-height: 350px;
}
.custom-control-input.is-invalid ~ .custom-control-label,
.was-validated .custom-control-input:invalid ~ .custom-control-label {
  color: #000;
}
.custom-control-input.is-valid ~ .custom-control-label,
.was-validated .custom-control-input:valid ~ .custom-control-label {
  color: #000;
}
/* else color #ecffe4 */
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip,
.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip {
/*  display: block; */
  opacity: 0;
  height: 0;
  display: none;
}
.btn-light.resend {
  padding: 6px 12px;
  margin-top: 37px;
  color: #aaa;
  background-color: #fff;
  border-color: #fff;
}
.btn-light.signup {
  color: #212529;
  background-color: #73e8f5;
  border-color: #fff;
}
.price-div {
  margin-top: 1.4rem;
  margin-right: 0px;
  margin-bottom: 1.2rem;
  margin-left: 0px;
  padding: 15px;
  background: #fff;
  font-weight: bold;
  color: red;
  font-size: 20px;
}
.club-member-signup .form-control {
  border: 3px;
}
.field-members-verification_code .form-control {
  border-top-color: #ff0000;
  border-right-color: #ff0000;
  border-bottom-color: #ff0000;
  border-left-color: #ff0000;
}
.field-members-verification_code label {
  color: #ff0000;
  text-transform: uppercase;
  font-weight: bold;
}
.btn.btn-danger.contact {
  padding: 10px 30px;
}
.site-contact .ambience {
  border: solid #fff 12px;
}
.site-contact .mapbox {
  margin-top: 30px;
}
.site-contact .btn,
.club-member-signup .btn {
  margin: 1.4rem 0 1.2rem 0;
  padding: .7rem 1.1rem;
}
.site-contact label {
  color: #fff;
}
.site-contact .form-group {
  margin-bottom: 14px;
}
.site-contact .highlighted-content p {
  color: #464646;
}
#contactform-verifycode-image {
  border: 1px solid #999;
  border-radius: 8px;
}
#contactform-verifycode.form-control {
  height: 51px;
}
.contact-form-tile h4 {
  margin-bottom: 1rem;
  text-transform: uppercase;
  font-size: 1.5rem;
  font-weight: normal;
  color: #ffa495;
}
#contact-form h3 {
  color: #e6c9a4;
}
#contact-form .btn.btn-light {
  background-color: #ffd491;
}
.contact-form-tile {
  padding: 20px;
  border: 1px #fff solid;
  border-radius: 10px;
  background-color: #74b5b54a;
}
.widgets-alerts .alert {
  position: absolute;
  z-index: 2;
}
img.decoration-members {
  border: 1px #fff solid;
  border-radius: 8px;
}
ol, ul {
  padding-left: 1.4rem;
}
